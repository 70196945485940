import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts/Layout'
import HeadingCta from '../components/flexible/HeadingCta'
import ComponentImageWrapper from '../components/ComponentImageWrapper'
import SingleCategories from '../components/SingleCategories'
import FlexibleContent from '../components/FlexibleContent'
import OtherPosts from '../components/OtherPosts'


//Component
const CaseTemplate = ( props ) => {

  const current_page = props.data.wordpressWpCase
  const other_pages = props.data.allWordpressWpCase
  const case_cta = props.data.allWordpressAcfOptions.edges[0].node.options
  const yoast_meta = current_page.yoast_meta
  
  const current_meta = {
    meta_title:     (yoast_meta) ? yoast_meta.yoast_wpseo_title : null,
    meta_desc:      (yoast_meta) ? yoast_meta.yoast_wpseo_metadesc : null,
  }

  const image_url = ( current_page.acf.main_image && current_page.acf.main_image.localFile ) ? current_page.acf.main_image.localFile.childImageSharp.fluid.src : null
  const cpt_categories = current_page.cpt_categories
  const flexible_content = current_page.acf.content_case

  const case_logo = current_page.acf.case_logo
  const logoSrc = ( case_logo && case_logo.localFile ) ? case_logo.localFile.childImageSharp.resize.src : null
  const logoAlt = ( case_logo && case_logo.alt_text ) ? case_logo.alt_text : null

  let cta_props = {
    content: {
      heading: case_cta.case_cta_heading,
      heading_size: case_cta.case_cta_heading_size,
      font_family: case_cta.case_cta_font_family,
      sub_heading_content: case_cta.case_cta_sub_heading_content,
      alignment: case_cta.case_cta_alignment,
    }
  }

  return (
    <Layout 
      current_meta = {current_meta} 
      className = "single-case"
    >

      <ComponentImageWrapper
        component = "ImageIntro"
        image_url = {image_url}
        align_bg_x = {current_page.acf.align_bg_x}
        align_bg_y = {current_page.acf.align_bg_y}
      >
        <div className="container-fluid text-center align-items-center">

          {logoSrc ? (
            <img className="logo mb-2 mb-lg-3" style={{maxWidth: '80%', maxHeight: 170}} src={logoSrc} alt={logoAlt} />
          ) : null}

          {current_page.acf.label ? (
            <div className="text-inset">
              <div className="text-inset__inner">
                <h1 className="size-h1-heading font-main" dangerouslySetInnerHTML={{__html: current_page.acf.label }} />

                <h2 className="size-h3-heading font-main">{current_page.title}</h2>
              </div>
            </div>
          ) : null}
          
        </div>
      </ComponentImageWrapper>

      {flexible_content ? ( 
        <FlexibleContent 
          use_first = {false}
          content = {flexible_content}
        />
      ) : null}


      {cpt_categories ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col pt-5 text-center">

              <SingleCategories 
                title="SKILLS USED"
                tonedDown={true}
                items={cpt_categories}
              />

            </div>
          </div>
        </div>
      ) : null}

      {cta_props ? (
        <HeadingCta {...cta_props}/>
      ) : null}

      {other_pages ? (
          <OtherPosts
            title="Fler vassa case"
            categories={cpt_categories}
            current_id={current_page.wordpress_id}
            posts={other_pages}
          />
        ) : null}

    </Layout>
  )
}


//Export
export default CaseTemplate


//Query
export const caseQuery = graphql`
query currentCaseQuery($id: String!) {
  wordpressWpCase(id: { eq: $id }) {
    wordpress_id
    title
    acf {
      label
      align_bg_x
      align_bg_y
      case_logo {
        alt_text
        localFile {
          childImageSharp {
            resize(width: 300) {
              width
              height
              src
            }
          }
        }
      }
      main_image {
        localFile {
          childImageSharp {
            fluid(maxWidth: 2000, maxHeight: 1500, cropFocus: CENTER) {
              ...Fluid
            }
          }
        }
      }
      content_case {
        ...contentFlexible
      }
    }
    cpt_categories {
      name
      slug
      link
    }
    yoast_meta {
      yoast_wpseo_title
      yoast_wpseo_metadesc
    }
  }
  allWordpressWpCase {
    edges {
      node {
        wordpress_id
        title
        link
        acf {
          label
          main_image {
            alt_text
            localFile {
              childImageSharp {
                fixed(width: 900, height: 600, cropFocus: CENTER) {
                  ...Fixed
                }
              }
            }
          }
        }
        cpt_categories {
          slug
        }
      }
    }
  }
  allWordpressAcfOptions {
    edges {
      node {
        options {
          case_cta_heading_size
          case_cta_alignment
          case_cta_font_family
          case_cta_heading
          case_cta_sub_heading_content {
            title
            url
            target
          }
        }
      }
    }
  }
}
`